<template>
  <ul class="menu-nav">
    <!-- <router-link
      class="menu-link menu-toggle"
      :to="{ name: 'dashboard' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon svg-icon svg-icon-xl">
            <inline-svg
              class="svg-icon"
              src="/media/svg/icons/Neolex/Basic/user.svg"
            />
          </span>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link> -->
    <template v-for="(menu, index) in menus">
      <li
        v-if="menu.subMenu"
        :key="index"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
      >
        <template v-if="menu.showCategory">
          <a class="menu-link menu-toggle">
            <span class="menu-icon svg-icon svg-icon-xl">
              <inline-svg class="svg-icon" :src="menu.icon" />
            </span>
            <span class="menu-text">{{ menu.name }}</span>
            <i class="menu-arrow"></i>
          </a>

          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <template v-for="(menuItem, i) in menu.subMenu">
                <router-link
                  v-if="menuItem.isShow"
                  :key="i"
                  class="menu-link menu-toggle"
                  :to="{ name: menuItem.to }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-line">
                        <span></span>
                      </i>
                      <span class="menu-text">{{ menuItem.name }}</span>
                    </a>
                  </li>
                </router-link>
              </template>
              <!-- <router-link
                class="menu-link menu-toggle"
                :to="{ name: 'user_group_list' }"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-line">
                      <span></span>
                    </i>
                    <span class="menu-text">Quản lý nhóm quyền</span>
                  </a>
                </li>
              </router-link> -->
            </ul>
          </div>
        </template>
      </li>
      <router-link
        v-else
        :key="index"
        class="menu-link menu-toggle"
        :to="{ name: menu.to }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <template v-if="menu.isShow">
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-icon svg-icon svg-icon-xl">
                <inline-svg class="svg-icon" :src="menu.icon" />
              </span>
              <span class="menu-text">{{ menu.name }}</span>
            </a>
          </template>
        </li>
      </router-link>
    </template>
    <!-- <router-link
      class="menu-link menu-toggle"
      :to="{ name: 'coaches_experts' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon svg-icon svg-icon-xl">
            <inline-svg
              class="svg-icon"
              src="/media/svg/icons/Communication/Group.svg"
            />
          </span>
          <span class="menu-text">Huấn luyện viên & chuyên gia</span>
        </a>
      </li>
    </router-link> -->
    <!-- SETUPS: END -->
    <!-- PATIENT: BEGIN -->
    <!-- <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
    >
      <a class="menu-link menu-toggle">
        <span class="menu-icon svg-icon svg-icon-xl">
          <inline-svg
            class="svg-icon"
            src="/media/svg/icons/Neolex/Basic/pulse.svg"
          />
        </span>
        <span class="menu-text">Quản lý bệnh nhân</span>
        <i class="menu-arrow"></i>
      </a>

      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            class="menu-link menu-toggle"
            :to="{ name: 'patient_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span class="menu-text">Danh sách bệnh nhân</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            class="menu-link menu-toggle"
            :to="{ name: 'history_kpis' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span class="menu-text">Lịch sử nhập liệu KPIs</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li> -->
    <!-- PATIENT: END -->
  </ul>
</template>

<script>
import intersection from 'lodash/intersection';
import { PAGE_CODE, PERMISSION_TYPE } from '@/core/plugins/constants';
export default {
  name: 'KTMenu',
  data() {
    return {
      menus: [
        {
          name: 'Quản lý tài khoản',
          icon: '/media/svg/icons/Code/Lock-overturning.svg',
          subMenu: [
            {
              name: 'Quản lý giới thiệu',
              to: 'tracking_share_app',
              pageCode: PAGE_CODE.TRACKING_SHARE_APP,
              permissions: [
                PERMISSION_TYPE.READ_ALL,
                PERMISSION_TYPE.READ_OWNER,
              ],
            },
            {
              name: 'Quản lý tài khoản Portal',
              to: 'user_portal_list',
              pageCode: PAGE_CODE.ACCOUNT_ACCOUNT_PORTAL,
              permissions: [
                PERMISSION_TYPE.READ_ALL,
                PERMISSION_TYPE.READ_OWNER,
              ],
            },
            {
              name: 'Quản lý tài khoản App',
              to: 'user_app_list',
              pageCode: PAGE_CODE.ACCOUNT_ACCOUNT_APP,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Quản lý vai trò',
              to: 'permission_role',
              pageCode: PAGE_CODE.ACCOUNT_ROLE,
              permissions: [PERMISSION_TYPE.READ],
            },
          ],
        },
        {
          name: 'Quản lý Khách hàng',
          icon: '/media/svg/icons/General/UserCircleGear.svg',
          subMenu: [
            {
              name: 'Kích hoạt tài khoản',
              to: 'active_account_list',
              pageCode: PAGE_CODE.CUSTOMER_GROUP,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Dashboard khách hàng',
              to: 'user_dashboard_find',
              pageCode: PAGE_CODE.USER_DASHBOARD,
              permissions: [
                PERMISSION_TYPE.READ_ALL,
                PERMISSION_TYPE.READ_OWNER,
              ],
            },
            {
              name: 'Dashboard nhóm huấn luyện',
              to: 'CoachCustomers',
              pageCode: PAGE_CODE.MY_CUSTOMER,
              permissions: [
                PERMISSION_TYPE.READ_ALL,
                PERMISSION_TYPE.READ_OWNER,
              ],
            },
            {
              name: 'Quản lý nhóm huấn luyện',
              to: 'CoachList',
              pageCode: PAGE_CODE.TRAINING_GROUP,
              permissions: [
                PERMISSION_TYPE.READ_ALL,
                PERMISSION_TYPE.READ_OWNER,
              ],
            },
            {
              name: 'Khách hàng trung tâm',
              to: 'customer_center',
            //  pageCode: PAGE_CODE.MY_CUSTOMER,
            pageCode: PAGE_CODE.CUSTOMER_CENTER,
              permissions: [
                PERMISSION_TYPE.READ
                // PERMISSION_TYPE.READ_OWNER,
              ],
            },

            {
              name: 'Danh sách tiếp nhận',
              to: 'customer_receives_list',
              pageCode: PAGE_CODE.CUSTOMER_RECEIVES,
              permissions: [PERMISSION_TYPE.READ],
            },
          ],
        },
        {
          name: 'Khóa học',
          icon: '/media/svg/icons/Home/Book-open.svg',
          subMenu: [
            {
              name: 'Quản lý cấp độ',
              to: 'level',
              pageCode: PAGE_CODE.LESSON_LEVEL,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Quản lý chủ đề',
              to: 'module',
              pageCode: PAGE_CODE.LESSON_MODULE,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Quản lý bài học',
              to: 'lesson',
              pageCode: PAGE_CODE.LESSON,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Quản lý câu hỏi',
              to: 'quize',
              pageCode: PAGE_CODE.QUIZ,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Danh Sách Khóa Học',
              to: 'courses_list',
              pageCode: PAGE_CODE.COURSES,
              permissions: [PERMISSION_TYPE.READ],
            },
          ],
        },
        {
          name: 'Quản lý Voucher',
          icon: '/media/svg/icons/Code/gift.svg',
          subMenu: [
            {
              name: 'Dashboard Voucher',
              to: 'voucher',
              pageCode: PAGE_CODE.VOUCHERS,
              permissions: [PERMISSION_TYPE.READ],
            },
          ],
        },
        {
          name: 'Danh sách Report',
          icon: '/media/svg/icons/Code/Thunder-circle.svg',
          subMenu: [
            {
              name: 'Daily Tracking App',
              to: 'report4',
              pageCode: PAGE_CODE.REPORT,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Tổng Hợp Dữ Liệu Sử Dụng App',
              to: 'report2',
              pageCode: PAGE_CODE.REPORT,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Báo cáo đầu vào',
              to: 'report3',
              pageCode: PAGE_CODE.REPORT,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Final Data DSMES',
              to: 'report1',
              pageCode: PAGE_CODE.REPORT,
              permissions: [PERMISSION_TYPE.READ],
            },
          ],
        },
        {
          name: 'Quản lý khảo sát',
          icon: '/media/svg/icons/Neolex/File/clipboard-text.svg',
          subMenu: [
            {
              name: 'Quản lý khảo sát',
              to: 'survey_list',
              pageCode: PAGE_CODE.SURVEY,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Quản lý bộ câu hỏi',
              to: 'survey_quize_list',
              pageCode: PAGE_CODE.SURVEY_QUESTION_GROUP,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Kết quả khảo sát',
              to: 'survey_result_list',
              pageCode: PAGE_CODE.SURVEY_QUESTION_GROUP,
              permissions: [PERMISSION_TYPE.READ],
            },
          ],
        },
        {
          name: 'Huấn luyện viên & chuyên gia',
          icon: '/media/svg/icons/Communication/Group.svg',
          subMenu: [
            {
              name: 'Dashboard khách hàng (cũ)',
              to: 'coaches_experts',
              pageCode: PAGE_CODE.COACHES_EXPERTS,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Quản lý đặt lịch',
              to: 'coaches_calendar',
              pageCode: PAGE_CODE.COACHES_CALENDAR,
              permissions: [PERMISSION_TYPE.READ],
            },
          ],
        },
        {
          name: 'Cổng giao tiếp',
          icon: '/media/svg/icons/Communication/Outgoing-mail.svg',
          subMenu: [
            {
              name: 'Thông báo',
              to: 'communication_list',
              pageCode: PAGE_CODE.COMMUNICATION,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Hỏi đáp chuyên gia',
              to: 'question_response',
              pageCode: PAGE_CODE.QUESTION,
              permissions: [PERMISSION_TYPE.READ],
            },
          ],
        },
        {
          name: 'Lịch làm việc',
          icon: '/media/svg/icons/Neolex/Time/calendar.svg',
          to: 'calendar_list',
          pageCode: PAGE_CODE.CALENDAR,
          permissions: [PERMISSION_TYPE.READ],
        },
        // {
        //   name: 'Zoom Video',
        //   icon: '/media/svg/icons/Neolex/Connection/broadcast.svg',
        //   to: 'zoom_login',
        //   pageCode: PAGE_CODE.ZOOMLOGIN,
        //   permissions: [PERMISSION_TYPE.READ],
        // },
        {
          name: 'Cài đặt',
          icon: '/media/svg/icons/Code/Settings4.svg',
          subMenu: [
            {
              name: 'Quản lý lộ trình',
              to: 'movement_route_list',
              pageCode: PAGE_CODE.AGENDA,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Tham số hệ thống',
              to: 'system_parameter',
              pageCode: PAGE_CODE.COMMON_CONFIGURE_TASK_PARAMETER,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Ngưỡng đường huyết',
              to: 'glucose_list',
              pageCode: PAGE_CODE.GLUCOSE,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Danh sách vận động',
              to: 'exercise_list',
              pageCode: PAGE_CODE.EXERCISE,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Danh sách món ăn',
              to: 'food_list',
              pageCode: PAGE_CODE.FOOD_CATEGORY,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Danh sách thực đơn mẫu',
              to: 'menu_list',
              pageCode: PAGE_CODE.FOOD_MENU,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Ngưỡng BMI / cân nặng',
              to: 'weight_list',
              pageCode: PAGE_CODE.BMI,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Cảm xúc',
              to: 'feeling_list',
              pageCode: PAGE_CODE.EMOTION,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Khung giờ & thời gian hoạt động',
              to: 'time_frame_of_operation_list',
              pageCode: PAGE_CODE.TIME_FRAME,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Quản trị nội dung',
              to: 'content',
              pageCode: PAGE_CODE.COMMON_CONFIGURE_TASK_CONTENT,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Hướng dẫn sử dụng',
              to: 'user_guide',
              pageCode: PAGE_CODE.PROFILE_INSTRUCTION,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Bài viết nổi bật',
              to: 'article',
              pageCode: PAGE_CODE.LEARNING_POST,
              permissions: [PERMISSION_TYPE.READ],
            },
            {
              name: 'Danh sách template tin nhắn',
              to: 'message_templates_list',
              pageCode: PAGE_CODE.MESSAGE_TEMPLATES,
              permissions: [PERMISSION_TYPE.READ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    permissions() {
      return this.$store.state.auth.permissions;
    },
  },
  watch: {
    permissions: {
      handler() {
        this.setMenu();
      },
      deep: true,
    },
  },
  mounted() {
    this.setMenu();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    setMenu() {
      this.menus.forEach((menu) => {
        if (menu.subMenu && menu.subMenu.length) {
          menu.subMenu.forEach((menuItem) => {
            if (!menuItem.pageCode) return (menuItem['isShow'] = true);
            menuItem['isShow'] = this.isShowMenuItem(
              menuItem.pageCode,
              menuItem.permissions,
            );
          });
          menu.subMenu.filter((el) => el.isShow).length > 0
            ? (menu['showCategory'] = true)
            : (menu['showCategory'] = false);
        } else {
          if (!menu.pageCode) return (menu['isShow'] = true);
          menu['isShow'] = this.isShowMenuItem(menu.pageCode, menu.permissions);
        }
      });
      this.$forceUpdate();
    },
    isShowMenuItem(pageCode, pagePermisstions) {
      const permissionWithPage =
        this.permissions
          .find((el) => el.code === pageCode)
          ?.value.map((el) => el.nameType) || [];

      const commonPermission = intersection(
        pagePermisstions,
        permissionWithPage,
      );
      return commonPermission.length > 0;
    },
  },
};
</script>
<style scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
